<template>
    <div class="success-container" :style="{ backgroundColor: primary_color }">
        <div class="content">
            <div style="display: flex;align-items: flex-start;">
                <img src="../../assets/image/logo.png" style="width: 200px; margin-right: 12px" />
                <img v-if="project == 'IBO'" src="../../assets/image/ibo_logo.png"
                    style="width: 300px;height: auto;object-fit: cover;" />
                <img v-else-if="project == 'WAO'" src="../../assets/image/wao_logo.png"
                    style="width: 160px;height: auto;object-fit: cover;" />
                <img v-else-if="project == 'AEO'" src="../../assets/image/aeo_logo.png" style="width: 200px;" />

                <img v-else-if="project == 'IPSYO'" src="../../assets/image/ipsyo_logo.png" style="width: 200px;height: auto;object-fit: cover;"/>

                <img v-else-if="project == 'IEnvO'" src="../../assets/image/ienvo.png" style="width: 223px;height: auto;object-fit: cover;margin-top: 10px;"/>

                <img v-else-if="project == 'IFO'" src="../../assets/image/ifo_logo.png" style="width: auto;height: 60px;margin-top: 10px;"/>

                <img v-else-if="project == 'BPA'" src="../../assets/image/bpa_logo.png" style="width: 265px;height: auto;object-fit: cover;"/>

                <img v-else-if="project == 'WEC'" src="../../assets/image/wec_logo.png" style="width: 220px;height: auto;object-fit: cover;"/>

                <img v-else-if="project == 'Envirothon'" src="../../assets/image/envirothon_logo.png" style="width: 110px;height: auto;object-fit: cover;margin-top: -15px;"/>

                <img v-else-if="project == '2025NSI' ||
                    project == '2025NRI' ||
                    project == '2025NIR' ||
                    project == '2025NIP' ||
                    project == '2025NIA' ||
                    project == '2025NIE' ||
                    project == '2025NII' || project == '2025NIV'
                " :src="createNimImgResource()" style="width: 180px;margin-top: 0px;margin-left: 20px;" />

                <img v-else-if="project == 'NIM'" src="../../assets/image/nim_logo.png"
                    style="width: 160px;margin-top: 10px;margin-left: 20px;" />
            </div>
            <div class="title1" :style="{ color: primary_color }">Submission Successful</div>
            <p class="register-text" :style="{ color: primary_color }">Your registration has been successfully
                submitted.
                Please check your email for the next steps.</p>
            <div class="submit">
                <div class="submitBtn" :style="{ backgroundColor: primary_color }" @click="gotoLogin">Confirm</div>
                <div class="submitBtn" :style="{ backgroundColor: primary_color }" @click="doResendEmail">Resend Email
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    sendEmailForPassOrder,
    sendEmailFroApplication
} from '../../api/eae'
export default ({
    name: 'index',
    data() {
        return {
            project: this.$route.query.project,
            id: this.$route.query.id,
            primary_color: '#FF6450'
        }
    },
    mounted() {
        if (this.project == 'AEO' || this.project == 'IEnvO') {
            this.primary_color = '#309139'
        } else if (this.project == 'WEC') {
            this.primary_color = '#D3A12A'
        } else if (this.project == 'BPA') {
            this.primary_color = '#AB2B29'
        } else if (this.project == 'IPSYO') {
            this.primary_color = '#E00044'
        } else if (this.project == 'Envirothon') {
            this.primary_color = '#00844D'
        } else if (this.project == 'IFO') {
            this.primary_color = '#E8CE60'
        }  else if (this.project == 'IBO') {
            this.primary_color = '#0a509A'
        } else if (this.project == 'WAO') {
            this.primary_color = '#043280'
        } else if (this.project == '2025NSI' ||
            this.project == '2025NRI' ||
            this.project == '2025NIR' ||
            this.project == '2025NIP' ||
            this.project == '2025NIA' ||
            this.project == '2025NIE' ||
            this.project == '2025NII' ||
            this.project == '2025NIV' ||
            this.project == 'NIM'
        ) {
            this.primary_color = '#0f448C'
        } else {
            this.primary_color = '#FF8E3B'
        }
    },
    methods: {

        createNimImgResource() {
            let project_code = this.project
            if (project_code == '2025NSI') {
                return require('../../assets/image/nsi_logo.png')
            }
            if (project_code == '2025NRI') {
                return require('../../assets/image/nri_logo.png')
            }
            if (project_code == '2025NIR') {
                return require('../../assets/image/nir_logo.png')
            }
            if (project_code == '2025NIP') {
                return require('../../assets/image/nip_logo.png')
            }
            if (project_code == '2025NIA') {
                return require('../../assets/image/nia_logo.png')
            }
            if (project_code == '2025NIE') {
                return require('../../assets/image/nie_logo.png')
            }
            if (project_code == '2025NII') {
                return require('../../assets/image/nii_logo.png')
            }
            if (project_code == '2025NIV') {
                return require('../../assets/image/niv_logo.png')
            }
        },


        gotoLogin() {
            this.$router.replace('/login')
        },
        doResendEmail() {
            if (this.project == 'WAO') {
                sendEmailForPassOrder(this.id).then((res) => {
                    this.$message.success('Resend Email Success~')
                })
                return
            }
            sendEmailFroApplication(this.id).then((res) => {
                this.$message.success('Resend Email Success~')
            })
        }
    }
})
</script>

<style scoped lang="scss">
.success-container {
    width: 100vw;
    height: 100vh;

    .register-text {
        text-align: center;
        width: 100%;
    }

    .content {
        overflow-y: auto;
        width: 960px;
        height: 100vh;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 30px 40px 20px;
        background: #fff;
        position: relative;

        .title1 {
            margin-top: 100px;
            font-family: GravityBold;
            font-size: 22px;
            margin-bottom: 30px;
            align-items: center;
            width: 100%;
            font-weight: bold;
            text-align: center;
        }
    }

    .submit {
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        .submitBtn {
            margin-right: 30px;
            font-family: GravityBold;
            width: 220px;
            height: 48px;
            border-radius: 25px;
            text-align: center;
            line-height: 48px;
            color: #fff;
            font-size: 20px;
            cursor: pointer;
        }

        margin-bottom: 50px;
    }


}
</style>