var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"success-container",style:({ backgroundColor: _vm.primary_color })},[_c('div',{staticClass:"content"},[_c('div',{staticStyle:{"display":"flex","align-items":"flex-start"}},[_c('img',{staticStyle:{"width":"200px","margin-right":"12px"},attrs:{"src":require("../../assets/image/logo.png")}}),(_vm.project == 'IBO')?_c('img',{staticStyle:{"width":"300px","height":"auto","object-fit":"cover"},attrs:{"src":require("../../assets/image/ibo_logo.png")}}):(_vm.project == 'WAO')?_c('img',{staticStyle:{"width":"160px","height":"auto","object-fit":"cover"},attrs:{"src":require("../../assets/image/wao_logo.png")}}):(_vm.project == 'AEO')?_c('img',{staticStyle:{"width":"200px"},attrs:{"src":require("../../assets/image/aeo_logo.png")}}):(_vm.project == 'IPSYO')?_c('img',{staticStyle:{"width":"200px","height":"auto","object-fit":"cover"},attrs:{"src":require("../../assets/image/ipsyo_logo.png")}}):(_vm.project == 'IEnvO')?_c('img',{staticStyle:{"width":"223px","height":"auto","object-fit":"cover","margin-top":"10px"},attrs:{"src":require("../../assets/image/ienvo.png")}}):(_vm.project == 'IFO')?_c('img',{staticStyle:{"width":"auto","height":"60px","margin-top":"10px"},attrs:{"src":require("../../assets/image/ifo_logo.png")}}):(_vm.project == 'BPA')?_c('img',{staticStyle:{"width":"265px","height":"auto","object-fit":"cover"},attrs:{"src":require("../../assets/image/bpa_logo.png")}}):(_vm.project == 'WEC')?_c('img',{staticStyle:{"width":"220px","height":"auto","object-fit":"cover"},attrs:{"src":require("../../assets/image/wec_logo.png")}}):(_vm.project == 'Envirothon')?_c('img',{staticStyle:{"width":"110px","height":"auto","object-fit":"cover","margin-top":"-15px"},attrs:{"src":require("../../assets/image/envirothon_logo.png")}}):(_vm.project == '2025NSI' ||
                _vm.project == '2025NRI' ||
                _vm.project == '2025NIR' ||
                _vm.project == '2025NIP' ||
                _vm.project == '2025NIA' ||
                _vm.project == '2025NIE' ||
                _vm.project == '2025NII' || _vm.project == '2025NIV'
            )?_c('img',{staticStyle:{"width":"180px","margin-top":"0px","margin-left":"20px"},attrs:{"src":_vm.createNimImgResource()}}):(_vm.project == 'NIM')?_c('img',{staticStyle:{"width":"160px","margin-top":"10px","margin-left":"20px"},attrs:{"src":require("../../assets/image/nim_logo.png")}}):_vm._e()]),_c('div',{staticClass:"title1",style:({ color: _vm.primary_color })},[_vm._v("Submission Successful")]),_c('p',{staticClass:"register-text",style:({ color: _vm.primary_color })},[_vm._v("Your registration has been successfully submitted. Please check your email for the next steps.")]),_c('div',{staticClass:"submit"},[_c('div',{staticClass:"submitBtn",style:({ backgroundColor: _vm.primary_color }),on:{"click":_vm.gotoLogin}},[_vm._v("Confirm")]),_c('div',{staticClass:"submitBtn",style:({ backgroundColor: _vm.primary_color }),on:{"click":_vm.doResendEmail}},[_vm._v("Resend Email ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }